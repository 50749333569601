import { createSSRApp } from 'vue'
import App from './App.vue'
import store from './store'
import { routeInterceptor, requestInterceptor, prototypeInterceptor } from './interceptors'
import Card from './components/Card.vue'
import Back from './components/Back.vue'
import MessageCard from './components/MessageCard.vue'
import 'virtual:uno.css'
import '@/style/index.scss'
import PLATFORM from './utils/platform'

uni.$zp = {
  config: {
    'default-page-size': '15',
    'back-to-top-bottom': PLATFORM.isMp ? '100px' : '40px',
  },
}

export function createApp() {
  const app = createSSRApp(App)
  app.use(store)
  app.use(routeInterceptor)
  app.use(requestInterceptor)
  app.use(prototypeInterceptor)
  app.component('Card', Card)
  app.component('Back', Back)
  app.component('MessageCard', MessageCard)
  return {
    app,
  }
}
