import { wxConfig } from '@/service/system'
import { useCommonStore } from '@/store'
import { currRoute } from '@/utils'
import PLATFORM from '@/utils/platform'
import qs from 'qs'

function useShareConfig({
  titleKey,
  descKey,
  title = undefined,
  desc = undefined,
  link = undefined,
  imgUrl = 'https://s21.ax1x.com/2024/10/06/pA8q2SH.png',
  params = {},
}) {
  const commonStore = useCommonStore()

  const init = () => {
    const route = currRoute()
    let shareUrl = link
    if (!link) {
      shareUrl = PLATFORM.baseUrl + '/#' + route.path
      const paramsObj = Object.assign(route.query, params)
      if (Object.keys(paramsObj)) {
        const params = qs.stringify(paramsObj)
        shareUrl += '?' + params
      }
    }
    const titleText = title || commonStore.systemProfile[titleKey]
    const descText = desc || commonStore.systemProfile[descKey]
    // h5分享
    jWeixin.updateAppMessageShareData({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
      success: function () {},
    })
    jWeixin.updateTimelineShareData({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
      success: function () {},
    })
  }

  // #ifdef MP-WEIXIN
  onShareAppMessage(() => {
    const titleText = title || commonStore.systemProfile[titleKey]
    const descText = desc || commonStore.systemProfile[descKey]
    console.log('titleText: ', titleText)
    return {
      title: titleText,
      desc: descText,
      imageUrl: imgUrl,
    }
  })
  // #endif

  onShow(async () => {
    if (PLATFORM.isMp) return
    await wxConfig().then((res) => {
      jWeixin.config({
        ...res.data,
        timestamp: res.data.timestamp + '',
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'checkJsApi',
          'onMenuShareTimeline', // 旧即将遗弃
          'onMenuShareAppMessage', // 旧即将遗弃
        ],
      })
    })
    if (!commonStore.systemProfile?.wechatShareTitle_fd) {
      await commonStore.handleGetUserProfile()
      init()
    } else {
      init()
    }
  })
}

export default useShareConfig
