<template>
  <view
    @click="handleBack"
    class="back-btn fixed right-38rpx bg-white w80rpx h80rpx border-rd-48rpx center flex-col"
    :style="`bottom: calc(120rpx + ${safeAreaInsets.bottom}px);`"
  >
    <image src="@img/back.png" class="w32rpx h32rpx" />
    <text class="text-20rpx">返回</text>
  </view>
</template>

<script setup lang="ts">
const props = defineProps<{ url?: string; backTab?: boolean }>()
const { safeAreaInsets } = uni.getWindowInfo()

const handleBack = () => {
  const url = props.url
  const backTab = props.backTab
  if (url) {
    if (backTab) {
      uni.switchTab({ url })
    } else {
      uni.redirectTo({ url })
    }
  } else {
    uni.navigateBack()
  }
}
</script>

<style scoped>
.back-btn {
  border: 1rpx solid rgba(39, 40, 34, 0.1);
}
</style>
